@import "../styles/vars.scss";

.confirmation-dialog-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 99;

    .confirmation-dialog-content {
        background-color: rgb(245, 245, 245);
        overflow: auto;
        border-radius: $br;
        outline: none;
        z-index: 5;
        display: flex;
        flex-direction: column;
        box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.3);

        .dialog-header {
            background-color: $primary-blue;
            padding: 15px;
            color: white;
            text-transform: uppercase;
            font-size: large;
            font-weight: bold;

            &.warning {
                background-color: $primary-red;
            }

            &.error {
                background-color: $primary-red;
            }
        }

        .dialog-content {
            margin-top: auto;
            text-align: center;

            p.question {
                margin: 20px 10px 15px 10px;
                line-height: 22px;
                font-size: 16px;
                font-weight: normal;
                word-break: keep-all;
            }
        }

        .dialog-buttons {
            margin: 40px 60px;
            display: flex;

            a:not(:last-child) {
                margin-right: 10px;
            }

            a:last-child {
                margin-left: 10px;

                &.orphan {
                    margin-left: auto;
                }
            }
        }
    }
}

.ReactModalPortal .confirmation-dialog-overlay {
    transition: opacity 250ms ease-in-out;

    &--before-close {
        opacity: 0;
    }
}

.ReactModalPortal .confirmation-dialog-content {
    transition: top 250ms ease-in-out, bottom 250ms ease-in-out;
    max-width: 400px;

    &.large-width {
        max-width: 750px;
    }

    &--before-close {
        top: -50%;
        bottom: 100%;
    }
}
