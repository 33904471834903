@import "../styles/vars.scss";

.language-selector {

  margin: 0;
  display: flex;
  list-style: none;

  li {
    display: inline-block;
    padding: 2px 6px;
    margin: 0;

    a {
      font-weight: normal;
      color: $primary-blue;
      text-decoration: none;

      &.active {
        font-weight: bold;
        color: black;
        cursor: default;

      }
    }
  }

}
