div.mod-applications {
  background-color: white;

  table.applications {

    thead {
      th {
        &.name {
          width: 15%;
        }

        &.displayName {
          width: 25%;
        }

        &.entityId {
          width: 20%;
        }

        &.landingPage {
          width: 20%;
        }

        &.provisioning {
          width: 20%;
        }
      }
    }
  }
}

