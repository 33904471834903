@mixin form {
  width: 75%;
  padding: 0 25px 25px 25px;

  @media (max-width: $mobile) {
    width: 100%;
    padding: 0 10px 10px 10px;
  }

}

h2 {
  margin: 35px 0 10px 0;
  color: $primary-green;
}

section.actions {
  margin-top: 30px;
  padding: 30px 0;
  border-top: 3px solid #e0e0df;
  display: flex;
  justify-content: flex-end;
  position: relative;

  a:not(:first-child) {
    margin-left: 25px;
  }

  a.delete {
    margin-right: auto;
    margin-left: 0;
  }

  span.error {
    position: absolute;
    top: 72px
  }


}

div.input-field, div.date-field, div.select-field {
  margin-top: 15px;
}

div.checkbox {
  margin-top: 25px;
}

textarea {
  font-family: "Source Sans Pro", sans-serif !important;
  background-color: white;
}



