@import "../styles/vars.scss";

div.checkbox {

    display: flex;
    align-items: center;

    input[type="checkbox"] {
        display: none;
    }

    label {
        display: inline-block;
        outline: none !important;
        font-weight: bold;
        &.info {
            margin-left: 10px;
        }

        &.disabled {
            color: $primary-grey;
        }
    }

    input[type="checkbox"] + label span {
        font-size: 11px;
        background-color: white;
        border: 1px solid $primary-blue;
        border-radius: 3px;
        padding: 1px 2px;
        margin: 2px 0;
        cursor: pointer;

        svg {
            color: transparent;
        }
    }

    input[type="checkbox"]:disabled + label span {
        border: 1px solid darken($tertiary-grey, 5%);
        background-color: $primary-grey;
        cursor: not-allowed;
    }

    input[type="checkbox"]:checked + label span {
        border: 3px solid $primary-blue;
        background-color: $primary-blue;
        border-radius: 2px;
        box-shadow: none;
        padding: 0;
        outline: none !important;

        svg {
            background-color: $primary-blue;
            color: white;
        }
    }

    input[type="checkbox"]:checked:disabled + label span {
        border: 3px solid $primary-grey;
        background-color: $primary-grey;
        cursor: default;

        svg {
            background-color: $primary-grey;
        }
    }

}