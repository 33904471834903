@import "../styles/vars";

div.users {
  background-color: white;

  table.users {

    thead {
      th {
        &.name {
          width: 20%;
        }

        &.email {
          width: 25%;
        }

        &.authority {
          width: 20%;
        }

        &.roles {
          width: 35%;
        }
      }
    }

    tbody tr td {
      span.me, ul.me {
        color: $primary-green;
      }
    }

  }
}

