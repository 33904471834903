@import "../styles/vars";

div.invitations {
    background-color: white;

    div.inviter {

        span {
            display: block;

        }
    }

    table.invitations {

        thead {
            th {
                &.email {
                    width: 20%;
                }

                &.authority {
                    width: 15%;
                }

                &.inviter {
                    width: 20%;
                }

                &.status {
                    width: 20%;
                }

                &.roles {
                    width: 25%;
                }
            }
        }

        tbody {
            tr {
                td {
                    span.open-invitation {
                        color: black;
                    }
                }

            }
        }
    }
}

