@import "../styles/vars.scss";

.tabs-container {
  width: 100%;
  background-color: $background;
  padding: 25px 20px 0 20px;

  .tabs {
    margin: 0 auto;
    display: flex;

    @media (max-width: $mobile) {
      flex-direction: column;
    }

  }

}
