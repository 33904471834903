@import "../styles/vars";

span.error-indication {
  color: $primary-red;

  display: flex;
  align-items: center;
  margin-top: 5px;

  &.standalone {
    margin-bottom: 15px;
  }

  svg {
    margin-right: 10px;
    width: 18px;
    height: auto;
  }
}