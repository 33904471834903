@import "../styles/vars.scss";

div.not-found {
  display: flex;
  height: 50vh;

  p {
    margin: auto;
    font-size: 164px;
    color: $secondary-grey;
  }
}