@import "../styles/vars.scss";

.date-field {
    display: flex;
    position: relative;
    flex-direction: column;

    label.date-field-label {
        display: inline-block;
        margin: 12px 0 6px 0;
        min-width: 210px;
        color: black;
        position: relative;
        font-weight: bold;
    }

    label.date-picker-container {
        display: flex;
        width: 240px;
        position: relative;
        border-radius: $br;

        &:focus {
            outline: none;
            box-shadow: 3px 3px 3px $light-blue, -3px -3px 1px $light-blue;
        }

        svg.fa-calendar-alt {
            position: absolute;
            right: 12px;
            top: 12px;
            color: $primary-blue;
            cursor: pointer;
            font-size: 20px;
        }
    }

    .react-datepicker-wrapper {
        width: 240px;
    }

    .react-datepicker__input-container {
        width: 100%;

        input[type="text"] {
            display: block;
            width: 100%;
            border-radius: $br;
            border: 1px solid $primary-grey;
            line-height: 33px;
            padding: 16px;
            font-size: 14px;
            height: 48px;

            &:focus {
                outline: none;
                box-shadow: 3px 3px 3px $light-blue, -3px -3px 1px $light-blue;
            }

            &[disabled] {
                cursor: not-allowed;
                background-color: $background;
            }
        }

        .react-datepicker__close-icon {
            top: -22px;
            right: -10px;
        }
    }
}