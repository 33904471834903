@import "../styles/vars";

.institution-guest {

  div.guest-applications {
    margin-bottom: 25px;

    p.attribute {
      margin-bottom: 6px;
    }

    span.new-role {
      color: $primary-green;
    }

    a {

      &.new-role {
        font-weight: bold;
      }
    }

    em.end-date {
      margin-bottom: 5px;
      display: block;
      font-size: 14px;
    }

  }

  div.disclaimer {
    background-color: #fef595;
    padding: 25px;
    margin: 15px 0 25px 0;
    font-size: 16px;
  }

}