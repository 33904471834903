// Colors
$primary-blue: #0062b0;
$light-blue: #ddf2fd;
$hover-blue: #003980;
$primary-green: #008738;
$primary-black: #202020;
$primary-red: #ff0000;
$secondary-red: #fff3f3;
$primary-orange: #f3830e;

$primary-grey: #d0d0d0;
$secondary-grey: #8e8e8e;
$tertiary-grey: #f5f5f5;
$inactive-grey: #505050;

$background: #f3f6f8;

$br: 6px;

$width-app: 1024px;
$mobile: 768px;
