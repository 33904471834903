.loader:empty,
.loader:empty:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.loader:empty {
    margin: 100px auto;
    font-size: 14px;
    position: relative;
    text-indent: -9999em;
    border: 1.5em solid #FFD700;
    border-left-color: white;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.4s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}