@import "../styles/vars.scss";

.header {
  display: flex;
  background-color: $primary-blue;
  align-items: center;
  align-content: center;
  position: relative;
  flex-direction: row;
  height: 80px;
  border-left: 28px solid $primary-blue;

  .logo {
    padding: 10px 0;
  }

  h1 {
    color: white;
    margin-left: 25px;

    @media (max-width: $mobile) {
      display: none;

    }


  }

  div.logout {
    display: flex;
    align-items: center;
    margin: 0 25px 0 auto;

    @media (max-width: $mobile) {
      margin: 0 5px 0 auto;
    }

  }

  a.profile-link {
    font-size: 24px;
    color: #fefd0d;
    margin: 0 25px 0 auto;
    @media (max-width: $mobile) {
      margin: 0 15px 0 5px;
    }

  }

  a.institution-guest-link {
    background-color: whitesmoke;
    font-size: 16px;
    border-radius: 50%;
    padding: 4px 6px;
    display: flex;
    align-content: center;
    color: $inactive-grey;
    margin: 0 25px 0 auto;
    @media (max-width: $mobile) {
      margin: 0 10px
    }

  }

}

