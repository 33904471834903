@import "../styles/vars.scss";
@import "../styles/forms.scss";

.landing-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .landing {
    margin: 0 auto;
    padding: 0 15px;
    max-width: $mobile;

    p.info {
      margin: 25px 0;
    }
  }

  div.disclaimer {
    background-color: #fef595;
    padding: 25px;
    margin: 15px 0 25px 0;
    font-size: 16px;
  }

}