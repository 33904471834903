@import "../styles/vars.scss";

.unit-header-container {
  padding: 20px 25px 25px 20px;
  display: flex;
  width: 100%;
  background-color: $background;

  @media (max-width: $mobile) {
    flex-direction: column;
  }


  div.info {
    display: flex;
    flex-direction: column;

    h2 {
      margin: 0 0 15px 0;
      color: $primary-green;
    }

    p.attribute {
      font-weight: bold;
      margin-top: 10px;
    }
  }

  div.actions {
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: column;

    a:not(:last-child) {
      margin-bottom: 15px;
    }
  }

}
