@import "../styles/vars.scss";

.select-field {
  display: flex;
  position: relative;
  flex-direction: column;

  label {
    display: inline-block;
    margin: 12px 0 6px 0;
    min-width: 210px;
    color: black;
    position: relative;
    font-weight: bold;
  }

  .input-select-inner {
    display: block;
    width: 100%;

    &.small {
      width: 240px;
    }

    border: 1px solid $secondary-grey;
    border-radius: $br;
    font-size: 16px;
    min-height: 48px;

    .select-inner__control {
      height: 100%;
      padding-left: 8px;
      border: none;
      border-radius: $br;
      min-height: 48px;
    }

    .select-inner__control--is-focused {
      outline: none;
      box-shadow: 3px 3px 3px $light-blue, -3px -3px 1px $light-blue;
      border: 1px solid $secondary-grey !important;
    }

    &.error {
      background-color: $secondary-red;
      border: 1px solid $primary-red;

      .select-inner__control {
        height: 100%;
        background-color: $secondary-red;
        border: none;
        border-radius: $br;
      }

      .select-inner__control--is-focused {
        outline: none;
        box-shadow: none;
        border: none!important;
      }
    }


    &[disabled] {
      background-color: $background;
      cursor: not-allowed;
    }

  }

  .select-inner__single-value--is-disabled {
    color: black;
  }

  .select-inner--is-disabled {
    cursor: not-allowed;
  }

  .select-inner__control--is-disabled {
    background-color: $background;
    border-radius: 6px;
  }
}