@import "../styles/vars.scss";

span.tool-tip-section {
  font-size: 12px;
  color: $primary-grey;
  cursor: pointer;
  padding: 0 4px;

  &:hover {
    color: darken($light-blue, 20%);
  }
}
