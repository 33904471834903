@import "../styles/vars.scss";

div.email-field {
  display: flex;
  position: relative;
  flex-direction: column;

  label {
    display: inline-block;
    margin: 12px 0 6px 0;
    min-width: 210px;
    color: black;
    position: relative;
    font-weight: bold;
  }

  .inner-email-field {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border: 1px solid $secondary-grey;
    border-radius: $br;
    font-size: 16px;
    background-color: white;

    &:focus-within {
      outline: none;
      box-shadow: 3px 3px 3px $light-blue, -3px -3px 1px $light-blue;
    }

    &.error {
      background-color: $secondary-red;
      border: 1px solid $primary-red;
    }

  }

  textarea {
    height: 70px;
    border: none;
    flex-grow: 2;
    display: inline-block;
    background-color: transparent;
    font-size: 16px;
    margin: 14px 5px 5px 5px;

    &:focus {
      border: none;
      outline: none;
    }
  }

  div.email-tag {
    border: 1px solid $primary-grey;
    border-radius: $br;
    margin: 10px 5px 0 5px;
    display: inline-block;
    position: relative;
    background-color: $tertiary-grey;
    padding: 4px 26px 4px 10px;
    max-height: 32px;

    span {
      &:first-child {
        word-break: break-all;
      }

      &:last-child {
        padding: 8px 14px;
        cursor: pointer;
        font-size: 14px;
        color: $primary-grey;
        position: absolute;
        right: -8px;
        top: -2px;

        &:hover {
          color: $primary-red;
        }

        &.disabled {
          cursor: not-allowed;
          color: $primary-green;
        }
      }
    }
  }

  .auto-complete {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #efefef;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: $br;
    z-index: 99;

    padding: 10px;
    width: 100%;

    span {
      cursor: pointer;
      padding: 4px;

      &.active {
        background-color: $primary-blue;
        color: white;
        border-radius: $br;
      }
    }
  }


}