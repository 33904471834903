@import "../styles/vars.scss";

.input-field {
    label {
        display: inline-block;
        margin: 12px 0 6px 0;
        min-width: 210px;
        color: black;
        position: relative;
        font-weight: bold;
    }

    .inner-input-field {
        position: relative;

        .copy-to-clipboard {
            position: absolute;
            right: 0;
            top: 0;
            height: 48px;
        }
    }


    display: flex;
    position: relative;
    flex-direction: column;

    input[type=text], input[type=number], textarea {
        display: block;
        width: 100%;
        border: 1px solid $primary-grey;
        padding: 6px 12px;
        border-radius: $br;
        font-size: 16px;
        height: 48px;
        color: black;

        &[disabled] {
            background-color: $background;
            cursor: not-allowed;
            color: black;
        }

        &.error {
            background-color: $secondary-red;
            border: 1px solid darken($primary-red, 20%);
        }

        &:focus {
            outline: none;
            box-shadow: 1px 1px 1px $primary-blue, -1px -1px 1px $primary-blue;
        }

    }

    textarea {
        height: 90px;

        &.large {
            height: 170px;
        }

        &.file-upload {
            height: 240px;
        }
    }

    span.remove {
        display: flex;
        align-items: center;

        em {
            display: inline-block;
            margin-right: 10px;
        }

        svg.fa-trash {
            margin: 0 5px 0 auto;
            color: $primary-red;

            &:hover {
                color: darken($primary-red, 10%) !important;
            }
        }
    }


}