@import "../styles/vars.scss";
@import "../styles/forms.scss";

.invitation-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .invitation {
        margin: 0 auto;
        max-width: $mobile;

        p.roles {
            margin-top: 5px;
        }

        div.already-accepted {
            display: flex;
            height: 50vh;

            p {
                margin-top: 15px;
                color: $primary-red;
            }
        }
    }

    div.disclaimer {
        background-color: #fef595;
        padding: 25px;
        margin: 25px 0;
        font-size: 16px;
    }

    div.terms {
        margin: 25px 0;
        background-color: $light-blue;
        padding: 5px 15px 30px 15px;
    }

    section.error {
        margin: 40px 0;
        color: $primary-red;
    }


}