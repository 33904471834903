@import "../styles/vars.scss";


.tab {
  min-width: 110px;
  padding: 10px 15px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 3px solid $inactive-grey;
  background-color: $background!important;

  @media (max-width: $mobile) {
    margin: 0;
  }


  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  svg {
    width: 20px;
    font-size: 18px;
    height: auto;
    margin-right: 10px;
  }

  &:not(.active) {
    cursor: pointer;

    p, svg {
      color: $inactive-grey;
    }

    &:not(.read-only):hover {
      p, svg {
        color: darken($inactive-grey, 10%);
      }

      border-bottom: 3px solid darken($inactive-grey, 10%);
    }

    &.read-only {
      cursor: not-allowed;

      p, svg {
        color: $secondary-grey;
      }

    }
  }

  &.active {
    border-bottom: 3px solid $primary-green;

    p, svg {
      color: $primary-green;
      font-weight: bold;
    }

  }

  span.notifier {
    border-radius: 50%;
    background-color: $primary-blue;
    color: white;
    font-size: 14px;
    display: inline-block;
    width: 18px;
    text-align: center;
    height: 18px;
    line-height: 18px;
    position: absolute;
    right: 0;
    top: -4px;


  }

}


