div.roles {
  background-color: white;

  table.roles {

    thead {
      th {
        &.name {
          width: 15%;
        }

        &.displayName {
          width: 30%;
        }

        &.authority {
          width: 20%;
        }

        &.applicationName, &.application__name {
          width: 15%;
        }

        &.auditable_createdAt {
          width: 20%;
        }

      }
    }

    tbody {
      td {
        padding-left: 5px;
      }
    }
  }
}


