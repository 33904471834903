div.institutions {
  background-color: white;

  table.institutions {

    thead {
      th {
        &.displayName {
          width: 25%;
        }

        &.entityId {
          width: 30%;
        }

        &.homeInstitution {
          width: 20%;
        }

        &.aupUrl {
          width: 25%;
        }
      }
    }

    tbody {
      td {
      }
    }
  }
}

