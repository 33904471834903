@import "../styles/vars";
@import "../styles/forms";

.profile-container {
  .profile {
    @include form;

    display: flex;
    flex-direction: column;

    .attributes {
      display: grid;
      grid-template-columns: 50% 50%;

      @media (max-width: $mobile) {
        grid-template-columns: 100%;
      }


      &.border-top {
        border-top: 1px solid $primary-grey;
        margin-top: 15px;
      }

    }

    p.attribute {
      font-weight: bold;
      margin-top: 20px;
    }

    div.user-role {
      display: flex;
      align-content: center;
      align-items: center;

      &.is-role {
        margin-bottom: 5px;
      }

      a.button.delete {
        margin-left: auto;
        height: 30px;
        font-size: 16px;
        padding: 6px 10px;

        &.user-role-delete {
          margin-top: 10px;
        }
      }
    }

    h2.section-divider {
      margin: 25px 0 0 0;
      font-size: 20px;
    }
  }
}